import service from "@/store/services/dashboard-service";

const state = {
  isLoading: false,
  dashboardData: [],
  activeTrips: [],
  //
  dashboardSortData: [],
  selectedCards: [],
  dashboardCardOptions: [],
};

const mutations = {
  SET_DASHBOARD_DATA(state, payload) {
    state.dashboardData = payload;
  },
  SET_ACTIVE_TRIPS(state, payload) {
    state.activeTrips = payload;
  },
  //
  SET_SECTION_OPTIONS(state, payload) {
    state.dashboardCardOptions = payload;
  },
  SET_SELECTED_CARD(state, payload) {
    state.selectedCards = payload.filter((r) => r.status == "on");
  },
  SET_DASHBOARD_SORT_DATA(state, payload) {
    state.dashboardSortData = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoading = true;
    service.dashboardData(params).then((data) => {
      store.commit("SET_DASHBOARD_DATA", data.body.data);
      store.state.isLoading = false;
    });
  },
  setActiveTrips: (store, params) => {
    store.state.isLoading = true;
    service.activeTripsDetail(params).then((data) => {
      store.commit("SET_ACTIVE_TRIPS", data.body.data);
      store.state.isLoading = false;
    });
  },
  //
  dashboardCardOptions: (store, params) => {
    store.state.isLoading = true;
    service.dashboardCardOptions(params).then((data) => {
      store.commit("SET_SECTION_OPTIONS", data.body.data);
      store.commit("SET_SELECTED_CARD", data.body.data);
      store.state.isLoading = false;
    });
  },

  userDashboardCards: (store, params) => {
    store.state.isLoading = true;
    service.userDashboardCards(params).then((data) => {
      store.commit("SET_DASHBOARD_SORT_DATA", data.body);
      store.state.isLoading = false;
    });
  },

  updateCard: (store, params) => {
    // store.state.isLoading = true;
    service.updateCard(params).then(() => {
      store.dispatch("dashboardCardOptions", {
        vehicle_tech: params.vehicle_tech,
        tenant_tech: params.tenant_tech,
      });
      store.dispatch("userDashboardCards", {
        vehicle_tech: params.vehicle_tech,
        tenant_tech: params.tenant_tech,
      });
      // store.state.isLoading = false;
    });
  },
};

const getters = {
  getIsLoading(state) {
    return state.isLoading;
  },

  getDashboadData(state) {
    return state.dashboardData;
  },
  getActiveTrips(state) {
    return state.activeTrips;
  },
  //
  getSelectedCards(state) {
    return state.selectedCards;
  },
  getCardOptions(state) {
    return state.dashboardCardOptions;
  },
  getDashboadSortData(state) {
    return state.dashboardSortData;
  },
};

const dashboardData = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dashboardData;
