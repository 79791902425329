import service from "@/helpers/service";

const state = {
  isLoading: false,
  metaData: {},
  list: [],
  //
  //
  //
};

const mutations = {
  SET_METADATA(state, payload) {
    state.metaData = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
};

const actions = {
  metaData: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("alerts-and-notification/alerts-metadata", params)
      .then((data) => {
        store.commit("SET_METADATA", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("alerts-and-notification/alerts", params)
      .then((data) => {
        // store.commit("SET_META", data.body.meta);
        store.commit("SET_LIST", data.body);
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
  add: (store, params) => {
    store.state.isLoading = true;
    return service
      .post("alerts-and-notification/alerts", params)
      .then((data) => {
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
  update: (store, params) => {
    store.state.isLoading = true;
    return service
      .put(`alerts-and-notification/alert-detail/${params.id}`, params)
      .then((data) => {
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
  destory: (store, params) => {
    store.state.isLoading = true;
    return service
      .destroy(`alerts-and-notification/alert-detail/${params.id}`)
      .then((data) => {
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.isLoading;
  },
  getMetaData(state) {
    return state.metaData;
  },
  getList(state) {
    return state.list;
  },
};

const alertsAndNotification = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default alertsAndNotification;
